import React, { useEffect, useCallback, useRef, useState, useLayoutEffect } from "react";
import styles from "./ServiceStatus.module.scss";
import { ServiceStatusThing, statusQuery } from "../../api/ecsLambdaAPI";
import Toggle from "../../components/Toggle";
import { classCombine } from "../../utils/style";
import _ from "lodash";

import refresh from "../../sync.svg";
import copy from "../../copy.svg";
import steam from "../../steam.svg";
import { gql, useMutation, useQuery } from "@apollo/client";

export function ServiceStatus() {
  const [polling, setPolling] = useState(false);

  const { loading, error, data, refetch } = useQuery
    <{ listGameServerStatuses: { items: { name: string, ip: string, desiredStatus: string, lastStatus: string, playerCount: number | null }[] } }>
    (statusQuery, {
      pollInterval: polling ? 200 : 0,
    });

  const serviceStatus = {
    loading,
    error,
    services: data?.listGameServerStatuses?.items.slice().sort(orderBy(serviceOrder, a => a.name)).map(item => ({
      name: item.name,
      status: item.lastStatus,
      desiredCount: item.desiredStatus === 'RUNNING' ? 1 : 0,
      runningCount: item.lastStatus === 'RUNNING' ? 1 : 0,
      publicIp: item.ip,
      playerCount: item.playerCount,
    })) ?? [],
  }

  useEffect(() => {
    const notStable = Object.values(serviceStatus.services).some(service => service.runningCount !== service.desiredCount);
    const needsToPoll = notStable;

    if (polling !== needsToPoll) {
      setPolling(needsToPoll);
    }
  }, [polling, setPolling, serviceStatus.services]);

  return (
    <div>
      <div
        className={styles.refreshButton}
        onClick={() => refetch()}
      >
        <img src={refresh} alt="refresh" className={classCombine({ [styles.refreshImg]: true, [styles.loading]: serviceStatus.loading })} />
      </div>
      <div
        className={styles.serviceContainer}>
        {serviceStatus.services.map((service) => (
          <ServiceStatusBox
            key={service.name}
            name={service.name}
            status={service}
          />
        ))}
      </div>

    </div>
  );
}

function orderBy<T>(order: string[], grabber: (item: T) => string) {

  return (a: T, b: T) => {
    let aIndex = order.indexOf(grabber(a));
    let bIndex = order.indexOf(grabber(b));

    aIndex = aIndex === -1 ? Infinity : aIndex;
    bIndex = bIndex === -1 ? Infinity : bIndex;

    return aIndex - bIndex;

  }
}

const serviceOrder = [
  // 'sandbox-test',
  'file-manager1',
  'valheim1',
  'valheim2',
];

const gameTypeLookup: { [key: string]: string } = {
  'valheim1': 'valheim',
  'valheim2': 'valheim',
}

const steamLookup: { [key: string]: { getSteamLink: (status: ServiceStatusThing) => string } } = {
  'valheim': {
    getSteamLink: function (status: ServiceStatusThing) {
      return `steam://run/892970//+connect ${status.publicIp}:2456 +password fourwordsalllowercase/`
    }
  },
}

function ServiceStatusBox(props: { name: string; status: ServiceStatusThing }) {
  const setServiceStatus = useSetServiceStatus();

  let classString = getClassString(props.status);

  const steamLink = steamLookup[gameTypeLookup[props.name]]?.getSteamLink(props.status)

  return (
    <div
      className={classCombine({
        [styles.box]: true,
        [styles[classString]]: true,
      })}
    >
      <div className={styles.boxHeader}>{props.name}</div>

      <div className={styles.dataContainer}>
        <div className={styles.dataBox}>
          <div className={styles.boxDns}>{props.name}<span className={styles.unimportantText}>.game.roboenator.com</span></div>
          <CopyThing value={`${props.name}.game.roboenator.com`} />
        </div>
        {props.status.publicIp && <div className={styles.dataBox}>
          <div className={styles.boxPublicIp}>{props.status.publicIp}</div>
          {steamLink && <a href={steamLink}>
            <img src={steam} alt="steam" className={classCombine({ [styles.steamImg]: true })} />
          </a>}
          <CopyThing value={props.status.publicIp} />
        </div>}
        {props.status.playerCount !== null && props.status.playerCount >= 0 ? `player count: ${props.status.playerCount}` : ''}
        <Toggle
          checked={!!props.status.desiredCount}
          onChange={checked => setServiceStatus(props.name, checked)}
        />
        <div className={styles.boxStatus}>{classString}</div>
      </div>

    </div>
  );
}

const SET_GAME_STATE = gql`
  mutation SetGameServerState($cluster: String!, $name: String!, $enabled: Boolean!) {
    setGameServerState(input: {cluster: $cluster, enabled: $enabled, name: $name}) {
      name
      enabled
    }
  }
`;

function useSetServiceStatus() {
  const [setGameState] = useMutation(SET_GAME_STATE, {
    update(cache, { data }) {
      cache.writeFragment({
        id: cache.identify({ name: data.setGameServerState.name, __typename: "GameServerStatus", }),
        fragment: gql`
          fragment desiredStatus on GameServerStatus {
                desiredStatus
            }
        `,
        data: {
          desiredStatus: data.setGameServerState.enabled ? "RUNNING" : "STOPPED",
        }
      })
    }
  });

  return (service: string, checked: boolean) => setGameState({
    variables: { cluster: 'terraform1', name: service, enabled: checked },
    optimisticResponse: {
      __typename: "Mutation",
      setGameServerState: {
        "name": service,
        "enabled": checked,
        "__typename": "SetGameServerResponse"
      }
    },
  })
}




function CopyThing(props: { value?: string }) {
  const imgRef = useRef<HTMLImageElement>(null);
  const [popupNumber, setPopupNumber] = useState<number>(0);
  const [popupText, setPopupText] = useState<string>('');
  const [floaterPosition, setFloaterPosition] = useState({ x: 0, y: 0 });
  const [savingUpCopy, setSavingUpCopy] = useState<boolean>(false);

  let copyToClipboard = useCallback(async () => {
    if (props.value) {
      setPopupText(`"${props.value}" written to clipboard`);
      try {
        await navigator.clipboard.writeText(props.value);
        setPopupNumber(popupNumber + 1);
      } catch (e) {
        setSavingUpCopy(true);
      }
    }
  }, [props.value, popupNumber, setPopupNumber]);

  useEffect(() => {
    const onchangeThing = async (ev: Event) => {
      if (savingUpCopy && props.value) {
        setSavingUpCopy(false);
        await navigator.clipboard.writeText(props.value);
        setPopupNumber(popupNumber + 1);
      }
    }
    //document visibilitychange
    window.addEventListener('focus', onchangeThing);
    return () => window.removeEventListener('focus', onchangeThing);
  }, [popupNumber, setPopupNumber, setSavingUpCopy, savingUpCopy, props.value])

  useLayoutEffect(() => {
    if (imgRef.current) {
      setFloaterPosition({ x: imgRef.current.x + 10, y: imgRef.current.y - 30 })
    }
  }, []);

  // useEffect(() => {
  //   if (props.value && !lastValue.current) {
  //     lastValue.current = props.value;
  //     copyToClipboard();
  //   }
  // }, [props.value, copyToClipboard]);

  return <>
    <img src={copy} ref={imgRef} alt="copy" className={classCombine({ [styles.copyImg]: true })}
      onClick={copyToClipboard} />
    {popupNumber > 0 && <div key={popupNumber} className={classCombine({ [styles.floater]: true, [styles.floaterGo]: !!props.value })}
      style={{ left: floaterPosition.x + 'px', top: floaterPosition.y + 'px' }}>
      {popupText}
    </div>}
  </>
}

function getClassString(status: ServiceStatusThing) {
  const desired = status.desiredCount;
  const running = status.runningCount;
  if (!desired && !running) {
    return "stopped";
  } else if (desired && !running) {
    return "starting";
  } else if (desired && running) {
    return "running";
  } else {
    return "stopping";
  }
}
