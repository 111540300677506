import React from "react";
import style from "./Toggle.module.css";

export default function Toggle(props: {
  checked: boolean;
  onChange: (checked: boolean) => void;
}) {
  return (
    <div>
      <label className={style.switch}>
        <input
          className={style.checkbox}
          type="checkbox"
          checked={props.checked}
          onChange={(event) => props.onChange(event.target.checked)}
        />
        <span className={style.slider}></span>
      </label>
      {/* <input className={style.toggle_input} type="checkbox" />
      <label className={style.toggle_label} >Toggle</label> */}
    </div>
  );
}
