import React from 'react';
import { ServiceStatus } from './features/serviceStatus/ServiceStatus';
import './App.css';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Counter />
      </header> */}
      
      <ServiceStatus />
    </div>
  );
}

export default App;
