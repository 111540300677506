
import { ApolloClient, gql, InMemoryCache } from '@apollo/client';


export async function makeCall(setThing?: SetThing): Promise<ReturnValue> {
  let body: any = {
    query: [
      "file-manager1",
      "valheim1",
      "valheim2",
      // "factorio1",
      // "factorio2",
      // "minecraft1",
      // "minecraft1-classic",
      // "minecraft2",
      // "terraria1"
    ],
    cluster: "terraform1",
    getIp: true,
  };
  if (setThing) {
    body.set = setThing;
  }
  let result = await fetch(
    "https://u15d98azzi.execute-api.ap-southeast-2.amazonaws.com/scale",
    {
      method: "POST",
      body: JSON.stringify(body),
    }
  );

  let json: ReturnValue = await result.json();

  return json;
}

const apiKey = 'da2-biyl2g7ixberzliql7juhd7eoa';

const wsUrl = "wss://rqm6w3t32nhb3bwaki4cnb66ui.appsync-realtime-api.ap-southeast-2.amazonaws.com/graphql";

export const client = new ApolloClient({
  uri: 'https://rqm6w3t32nhb3bwaki4cnb66ui.appsync-api.ap-southeast-2.amazonaws.com/graphql',
  cache: new InMemoryCache({
    typePolicies: {
      GameServerStatus: {
        keyFields: ['name']
      }
    }
  }),
  headers: {
    'X-Api-Key': apiKey
  }
});


export const statusQuery = gql`
query listGameServerStatuses {
  listGameServerStatuses {
    items {
      name
      ip
      desiredStatus
      lastStatus
      playerCount
    }
  }
}
`

function doThing() {

  const aaaaa = client.readQuery({
    query: gql`
    query GameServerStatus {
      GameServerStatus {
        name
        ip
        desiredStatus
        lastStatus
      }
    }
  `,
  });

  // client.cache.

  // console.log(aaaaa);

  return aaaaa
  // client
  //   .query({ query: statusQuery })
  //   .then(result => console.log(result));

}

(window as any).doThing = doThing;


export type SetThing = { [service: string]: boolean };

export interface ServiceStatusThing {
  status: string;
  desiredCount: number;
  runningCount: number;
  // pendingCount: number;
  playerCount: number | null;
  publicIp?: string;
}

export interface ReturnValue {
  success: boolean;
  error?: string;
  data: ServiceStatusThing[];
}
